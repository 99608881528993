<template>
	<section class="permission">
		<el-col :span="24" class="toolbar">
			<el-button type="primary" @click="upLoadApp" icon="el-icon-plus">新增版本</el-button>
		</el-col>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">	
			<el-table-column prop="app_version" label="版本号" width="100" align="center"></el-table-column>
			<el-table-column prop="url" label="更新系统" width="120">
				<template slot-scope="scope">
					<span class="mr10" v-if="scope.row.url">安卓</span>
					<!-- <span v-if="scope.row.ios_url">苹果</span> -->
				</template>
			</el-table-column>
			<el-table-column prop="createtime" label="更新时间" sortable width="150"></el-table-column>
			<el-table-column prop="desci" label="说明" width="250"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="editorInfo(scope.row)">修改</el-button>
					<el-button type="primary" plain size="small" @click="seeLink(scope.row)" >查看链接</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="查看版本信息" :visible.sync="dialogShow" width="600px" center>
			<p class="mb15">版本: {{ appInfo.app_version }}</p>
			<p class="mb15">说明: {{ appInfo.desci }}</p>
			<div v-if="appInfo.url">安卓：{{appInfo.url}}</div>
			<!-- <div v-if="appInfo.ios_url">苹果：{{appInfo.ios_url}}</div> -->
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogShow = false">关 闭</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="nowUserInfo.id?'番茄云APP版本编辑':'番茄云APP版本更新'" :visible.sync="showUpox" width="600px" center>
			<el-form label-width="120px">
				<el-form-item label="当前地址：" v-if="nowUserInfo.url || nowUserInfo.ios_url ">
					<div v-if="nowUserInfo.url">安卓：{{nowUserInfo.url}}</div>
					<!-- <div v-if="nowUserInfo.ios_url">苹果：{{nowUserInfo.ios_url}}</div> -->
				</el-form-item>
				<el-form-item label="Android包上传：">
					<el-upload class="upload-demo" :action="uploadurl" :data="dataToken" :on-success="uploadurlApk" :beforeUpload="beforeAvatarUpload" :file-list="fileList">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<el-form-item label="底层：">
					<el-radio v-model="nowUserInfo.type" label="android">原生</el-radio>
					<el-radio v-model="nowUserInfo.type" label="uniapp">UNI-APP</el-radio>
				</el-form-item>
				<el-form-item label="版本号：">
					<el-input v-model="nowUserInfo.app_version" placeholder="版本号" class="w300"></el-input>
				</el-form-item>
				<el-form-item label="版本说明：">
					<el-input v-model="nowUserInfo.desci" placeholder="版本说明" class="w300"></el-input>
				</el-form-item>
				<el-form-item label="App状态：">
					<el-switch v-model="nowUserInfo.status" :active-value= "1" :inactive-value= "0" :disabed="nowUserInfo.status==='1'" active-color="#13ce66" inactive-color="#ff4949" ></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updateUser" :style="{ display: showUpdateUser}">上 传</el-button>
				<el-button type="primary" @click="appupdateUser" :style="{ display: showAppupdateUser}">更 新</el-button>
				<el-button @click="hideAddBox">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import { userAppList, userAppAdd, getUploadToken, userAppUpdate } from '../../api/api';
	export default {
		name:'userAppMan',
		data() {
			return {
				fileList: [],
				dialogShow: false,
				showUpox: false,
				appInfo:{},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				uploadurl: process.env.VUE_APP_Qiniu, //上传地址
				dataToken: {},//上传文件需要携带的参数
				urlHead: '',
				nowUserId: '',
				nowUserInfo:{
					url: '',
					// ios_url: '',
					type: 'android',
					app_version: '',
					desci: '',
					status: '',
					apk_size: ''
				},
				showUpdateUser: '',
				showAppupdateUser: ''
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			editorInfo(data){
				this.showUpdateUser = 'none'
				this.showAppupdateUser = ''
				this.nowUserInfo.id = data.id
				this.nowUserInfo.url = data.url
				// this.nowUserInfo.ios_url = data.ios_url
				this.nowUserInfo.app_version = data.app_version
				this.nowUserInfo.desci = data.desci
				// console.log(data.status)
				this.nowUserInfo.status = data.status
				this.showUpox = true;
			},
			upLoadApp(){
				this.showUpdateUser = ''
				this.showAppupdateUser = 'none'
				this.appInfo = {};
				this.showUpox = true
			},
			async beforeAvatarUpload(file) {
				await getUploadToken({docType: 'apk'}).then((res) => {
					this.dataToken.token = res.data.uptoken
					this.dataToken.key = res.data.key
					this.urlHead = 'https://'+ res.data.url + '/'
				});
				this.nowUserInfo.apk_size = file.size
				var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
				const extension = testmsg === 'zip', extension1 = testmsg === 'rar', extension2 = testmsg === 'apk';
                if(!extension && !extension1 && !extension2) {
                    this.$message.error('上传的文件只能是zip、rar或apk格式!');
                }
                return (extension || extension1 || extension2)
            },
			uploadurlApk(response){
				this.nowUserInfo.url = this.urlHead + response.key
				// this.updateUser();
			},			
			//获取列表
			getList(type) {
                if(type) this.page = 1
				let para = {
					page : this.page,
					pagesize : this.pagesize
				}				
				this.listLoading = true;
				userAppList(para).then((res) => {
					this.listLoading = false;
					if(res.code == 1){
						this.listData = res.data;
						console.log(res.data)
						// this.total = res.data.count;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//上传
			updateUser(){
				if(!this.nowUserInfo.url && !this.nowUserInfo.ios_url) return this.$message.error('请选择上传文件或输入下载地址');
				this.showUpox = false;
				this.listLoading = true;
				let para = {...this.nowUserInfo}
				// console.log(this.nowUserInfo)
				userAppAdd(para).then((res) => {
					if(res.code == 1){
						this.getList();
						this.hideAddBox()
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//app更新
			appupdateUser(){
				if(!this.nowUserInfo.url && !this.nowUserInfo.ios_url) return this.$message.error('请选择上传文件或输入下载地址');
				this.showUpox = false;
				this.listLoading = true;
				let para = {...this.nowUserInfo}
				// console.log(this.nowUserInfo)
				userAppUpdate(para).then((res) => {
					if(res.code == 1){
						this.getList();
						this.hideAddBox()
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			hideAddBox(){
				this.showUpox = false
				this.clearData(this.nowUserInfo)
				this.fileList = []
			},
			seeLink:function(data){
				this.appInfo = data;
				this.dialogShow = true
			}
		},
		mounted() {
			//获取列表数据
			this.getList();
			//获取上传文件token
			// this.getUploadTokenM();
		}
	}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
</style>